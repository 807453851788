<template>
  <div class="about">
    <div class="card">
      <img
        src="https://gravatar.com/avatar/66b6ea280a135ce23cdc97a2eae0d50284710b6340eee3df8c5d3f86dc3d02bf?s=500"
        alt="profile-photo"
        loading="lazy"
      />
      <h1>Adrián Rey Brea</h1>
      <h2>{{ $t("aboutInfo.job") }}</h2>
      <h3><span class="icon">✉️</span> adrian@adrianrey.dev</h3>
      <h3><span class="icon">📞</span> +34 644 92 25 48</h3>
      <h2 class="links-text">{{ $t("aboutInfo.links") }}:</h2>
      <h3 class="rrss">
        <a
          href="https://www.linkedin.com/in/adrián-rey-brea-9657061b1"
          target="_blank"
        >
          <font-awesome-icon :icon="['fab', 'linkedin']" />
          LinkedIn
        </a>
        <a href="https://www.instagram.com/rey.code_" target="_blank">
          <font-awesome-icon :icon="['fab', 'square-instagram']" /> Instagram
        </a>
        <a href="https://twitter.com/reycode_" target="_blank">
          <font-awesome-icon :icon="['fab', 'square-x-twitter']" /> X
        </a>
        <a
          href="https://wa.me/+34644922548?text=%C2%A1Hola%20Adri%C3%A1n%21%20He%20visto%20tu%20portfolio%20online%20en%20https%3A%2F%2Fadrianrey.dev%20y%20quisiera%20trabajar%20contigo."
          target="_blank"
        >
          <font-awesome-icon :icon="['fab', 'square-whatsapp']" /> Whatsapp
        </a>
      </h3>
    </div>
    <div class="bio">
      <h1>👋🏻 {{ $t("aboutMe") }} 👨🏻‍💻</h1>
      <p v-for="i in count" :key="i">
        {{ $t(`aboutInfo.paragraphs.${i - 1}`) }}
      </p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.about {
  display: flex;
  gap: 20px;
  background-color: #00ff9580;
  padding: 10px;
  margin: 20px;
  border-radius: 10px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
  @media screen and (min-width: 1400px) {
    width: calc(100% - 340px);
  }
  @media screen and (min-width: 1500px) {
    width: calc(100% - 440px);
  }
  @media screen and (min-width: 1600px) {
    width: calc(100% - 540px);
  }
  p {
    margin: 10px;
  }
}

.card {
  padding: 10px;
  flex-grow: 0;
  flex-shrink: 0;
  height: max-content;
  border-radius: 10px;
  background-color: #00ff9580;
  > img {
    left: 50%;
    width: 220px;
    transform: translateX(-50%);
    border-radius: 100%;
  }
  > h1,
  > h2 {
    text-align: center;
    color: var(--main-color);
  }
  > h2 {
    color: var(--color-text);
  }
  > h3 {
    font-weight: 400;
    > .icon {
      color: var(--main-color);
      font-weight: 800;
    }
  }
  .rrss {
    display: flex;
    flex-direction: column;
    a {
      text-decoration: none;
      color: inherit;
      background: var(--app-green-transparent-darker);
      border-radius: 10px;
      margin: 3px;
      padding: 2px;
    }
    :hover {
      color: var(--main-color);
      background-color: var(--app-green-darker);
    }
    svg {
      height: 20px;
      margin: 0 5px;
    }
  }
  .links-text {
    text-align: left !important;
    margin-left: 10px;
  }
}

.bio {
  width: fit-content;
  p {
    font-size: 19px;
    font-weight: 300;
    text-align: justify;
  }
}
</style>
<script setup lang="ts">
import enUS from "@/locales/en-US";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
const count = new enUS().aboutInfo.paragraphs.length;
</script>
