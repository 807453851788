<template>
  <div class="info-div">
    <img :src="require(`@/assets/logo.png`)" alt="vue logo" />
    <div>
      <h1>
        {{ $t("aboutHeader") }}
      </h1>
      {{ $t("aboutProject_1") }}
      <br />
      {{ $t("aboutProject_2") }}
      <br />
      <ul>
        <li>
          <a href="https://animate.style/" target="_blank">Animate.css</a>
        </li>
        <li><a href="https://pinia.vuejs.org/" target="_blank">Pinia</a></li>
        <li>
          <a href="https://router.vuejs.org/" target="_blank">Vue Router</a>
        </li>
        <li>
          <a href="https://kazupon.github.io/vue-i18n/" target="_blank">
            i18n Vue
          </a>
        </li>
        <li>
          <a href="https://epic-spinners.epicmax.co/" target="_blank"
            >Epic Spinners</a
          >
        </li>
        <li>
          <a href="https://github.com/tsparticles/vue3" target="_blank"
            >TS Particles</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.info-div {
  display: flex;
  gap: 20px;
  background-color: var(--app-green-transparent);
  margin: 20px;
  padding: 10px;
  border-radius: 10px;
  animation: fadeIn;
  animation-duration: 0.5s;
  div {
    flex-grow: 1;
    > ul {
      padding: 0;
      display: flex;

      gap: 10px;
      > li {
        flex-grow: 1;
        list-style: none;
        background: var(--app-green-transparent);
        border-radius: 50px;
        margin-top: 10px;

        > a {
          text-align: center;
          display: block;
          text-decoration: none;
          color: white;
          padding: 20px;
        }
      }

      > li:hover {
        transform-origin: center;
        transform: scale(1.02);
        background: var(--abilities-card);
      }
    }
  }
  img {
    width: 200px;
    height: min-content;
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
    img {
      align-self: center;
    }
  }
  @media screen and (max-width: 1000px) {
    div > ul {
      flex-direction: column;
    }
  }
}
</style>
