export default class ParticlesBackground {
  private readonly fpsLimit: number;
  private readonly interactivity: {
    events: {
      onClick: {
        enable: boolean;
        mode: string;
      };
      onHover: {
        enable: boolean;
        mode: string;
      };
      resize: boolean;
    };
    modes: {
      bubble: {
        distance: number;
        duration: number;
        opacity: number;
        size: number;
      };
      push: {
        quantity: number;
      };
      repulse: {
        distance: number;
        duration: number;
      };
    };
  };
  private readonly particles: {
    color: {
      value: string;
    };
    links: {
      color: string;
      distance: number;
      enable: boolean;
      opacity: number;
      width: number;
    };
    move: {
      direction: string;
      enable: boolean;
      outMode: string;
      random: boolean;
      speed: number;
      straight: boolean;
    };
    number: {
      density: {
        enable: boolean;
        area: number;
      };
      value: number;
    };
    opacity: {
      value: number;
    };
    shape: {
      type: string;
    };
    size: {
      random: boolean;
      value: number;
    };
  };
  private readonly detectRetina: boolean;
  constructor(mainColor: string) {
    this.fpsLimit = 120;
    this.interactivity = {
      events: {
        onClick: {
          enable: false,
          mode: "push",
        },
        onHover: {
          enable: false,
          mode: "repulse",
        },
        resize: true,
      },
      modes: {
        bubble: {
          distance: 400,
          duration: 2,
          opacity: 0.8,
          size: 40,
        },
        push: {
          quantity: 4,
        },
        repulse: {
          distance: 200,
          duration: 0.4,
        },
      },
    };
    this.particles = {
      color: {
        value: mainColor,
      },
      links: {
        color: mainColor,
        distance: 150,
        enable: true,
        opacity: 0.5,
        width: 1,
      },
      move: {
        direction: "none",
        enable: true,
        outMode: "bounce",
        random: true,
        speed: 6,
        straight: true,
      },
      number: {
        density: {
          enable: true,
          area: 800,
        },
        value: 80,
      },
      opacity: {
        value: 0.5,
      },
      shape: {
        type: "circle",
      },
      size: {
        random: true,
        value: 5,
      },
    };
    this.detectRetina = true;
  }
}
