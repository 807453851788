import { createApp } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { createPinia } from "pinia";
import App from "@/App.vue";
import router from "@/router";
import "./assets/main.css";
import Particles from "vue3-particles";
import { createI18n } from "vue-i18n";
import EnUS from "@/locales/en-US";
import EsES from "@/locales/es-ES";
import Default from "@/locales/Default";
import "animate.css";

import { useDataStore } from "@/pinia/DataStore";
library.add(fab, far, fas);
const i18n = createI18n<[Default], "en-US" | "en" | "es-ES" | "es">({
  locale: navigator.language,
  fallbackLocale: "en-US",
  legacy: false,
  messages: {
    "en-US": new EnUS(),
    "es-ES": new EsES(),
    en: new EnUS(),
    es: new EsES(),
  },
});
createApp(App)
  .component("FIcon", FontAwesomeIcon)
  .use(router)
  .use(i18n)
  .use(Particles)
  .use(createPinia())
  .mount("#app");
useDataStore();
