import { Client, Databases, Models } from "appwrite";

/**
 * Class that connects to appwrite instance extending its vendor class Client
 */
export default class AppwriteConnection extends Client {
  private _databases: Databases;
  private static readonly ABILITIES = "ABILITIES";
  private static readonly ABILITIES_GROUP = "ABILITIES_GROUP";
  constructor() {
    super();
    this.setEndpoint(process.env.VUE_APP_APPWRITE_ENDPOINT as string);
    this.setProject(process.env.VUE_APP_APPWRITE_PROJECT as string);
    this._databases = new Databases(this);
  }

  /**
   * Function that retrieves a value from Appwrite's DB
   * @param collection collection
   * @param _id id of the entry
   */
  private async getDocument(
    collection: string,
    _id: string
  ): Promise<Models.Document> {
    return await this._databases.getDocument(
      process.env.VUE_APP_APPWRITE_DATABASE as string,
      collection,
      _id
    );
  }

  /**
   * Function that retrieves a value from Appwrite's DB
   * @param collection collection
   */
  private async listDocuments(collection: string) {
    return await this._databases.listDocuments(
      process.env.VUE_APP_APPWRITE_DATABASE as string,
      collection
    );
  }

  public async listAbilities() {
    return this.listDocuments(AppwriteConnection.ABILITIES);
  }

  public async listAbilitiesGroup() {
    return this.listDocuments(AppwriteConnection.ABILITIES_GROUP);
  }
}
