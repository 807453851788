<template>
  <vue-particles
    id="tsparticles"
    :particlesInit="particlesInit"
    :particlesLoaded="particlesLoaded"
    :options="refParticles"
  />
  <router-view />
</template>
<script setup lang="ts">
import { loadSlim } from "tsparticles-slim";
import ParticlesBackground from "@/classes/ParticlesBackground";
import { ref } from "vue";
let mainColor = getComputedStyle(document.documentElement)
  .getPropertyValue("--main-color")
  .trim();
const refParticles = ref(new ParticlesBackground(mainColor));
const particlesInit = async (engine: never) => {
  await loadSlim(engine);
};
const particlesLoaded = async (container: never) => {
  console.log("Particles container loaded", container);
};
window
  .matchMedia("(prefers-color-scheme: dark)")
  .addEventListener("change", ({ matches }) => {
    console.log("Color change", matches);
  });
</script>
