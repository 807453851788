import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Inicio from "@/views/InicioView.vue";
import AboutView from "@/views/AboutView.vue";
import AbilitiesView from "@/views/AbilitiesView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "root",
    component: () => import("@/layouts/DefaultLayout.vue"),
    children: [
      {
        path: "/",
        name: "home",
        component: Inicio,
      },
      {
        path: "/abilities",
        name: "abilities",
        component: AbilitiesView,
      },
      {
        path: "/about",
        name: "about",
        component: AboutView,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
