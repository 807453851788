import DefaultLocales from "./Default";

export default class esES implements DefaultLocales {
  readonly changeLocale = "Cambiar idioma";
  readonly workInProgress = "Esto es un trabajo en curso";
  readonly home = "Inicio";
  readonly about = "Acerca de";
  readonly aboutHeader = "Acerca del proyecto";
  readonly abilities = "Habilidades y conocimiento";
  readonly aboutMe = "Sobre mi";
  readonly aboutProject_1 = `Este proyecto ha sido construido con Vue 3, escrito en Typescript,
   y usando Appwrite, que es una alternativa Open Source a Firebase, creditos a mí, Adrián Rey Brea por el desarrollo de este.`;
  readonly aboutProject_2 = "Otras librerias que han sido usadas:";
  readonly errorDataAppwrite =
    "No se han podido obtener los datos de Appwrite, intentalo de nuevo";
  readonly loadingAppwrite = "Obteniendo datos de Appwrite, espere por favor";
  readonly aboutInfo = {
    job: "Software Developer",
    links: "Enlaces",
    paragraphs: [
      "¡Hola!, mi nombre es Adrián Rey Brea, soy un desarrollador de software ubicado en San Andrés del Rabanedo en León, España.",
      "Los dos ultimos años he estado trabajando en un e-commerce llamado Craftelier (anteriormente llamado Mi Tienda de Arte), desarrollando aplicaciones web escritas en PHP (usando Laravel / Symfony) y con TypeScript (FullStack Vue 3 con NestJS), también he estado encargado del mantenimiento de servidores y despliegue de aplicaciones a través de CI/CD y Docker, y también he estado a cargo del despliegue de servidores para servicios como GitLab.",
      "Empecé a estudiar en 2019 hasta 2021 el CFGS de Desarrollo de Aplicaciones Multiplataforma, donde aprendí a programar en Java 11, Python, y aprendí también SQL usando diferentes gestores como MySQL, SQLite, PostgreSQL y Oracle, obteniendo asi el título que me acredita como desarrollador.",
      "El proyecto de fin de curso fué una aplicación llamada MultiSQL Workbench (llamado así como una parodia de MySQL Workbench), escrita en Java Swing permitiendo el uso de diferentes motores de bases de datos como SQLite, PostgreSQL y Oracle.",
      "Después de graduarme empecé a trabajar a tiempo parcial en una empresa llamada Proconsi, donde empecé a trabajar con Spring, mientras que al mismo tiempo me apunté al CFGS de Desarrollo de Aplicaciones Web (2021 - 2022, el primer año era común con el anterior CFGS), para expandir mis estudios, aqui aprendí sobre PHP, Laravel, SCSS, Bootstrap y React.",
      "De proyecto de fin de curso, los profesores nos pidieron a unos alumnos hacer un proyecto colaborativo que consistia en el desarrollo de una aplicación para el instituto, que fue desarrollada con React como FrontEnd conectando con un BackEnd escrito en Spring y usando Firebase como Base de datos.",
    ],
  };
}
