import { defineStore } from "pinia";
import AppwriteConnection from "@/classes/AppwriteConnection";

export const useDataStore = defineStore("data", {
  state: () => {
    console.log("Getting Appwrite Data");
    const appwriteConnection = new AppwriteConnection();
    console.log("Data retrieved");
    return {
      appwriteInstance: appwriteConnection,
    };
  },
});
