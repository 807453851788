<template>
  <div class="loading-div" v-if="!loaded && !errorLoading">
    <h1>{{ $t("loadingAppwrite") }}</h1>
    <breeding-rhombus-spinner :animation-duration="2000" :size="65" />
  </div>
  <div class="abilities-div" v-else-if="loaded && !errorLoading">
    <h1>🔧 {{ $t("abilities") }} 💻</h1>
    <div v-for="document of refData.documents" :key="document.$id">
      <h3>
        {{ i18n.locale.value == "es-ES" ? document.group_es : document.group }}
      </h3>
      <ul>
        <li v-for="ability of document.abilities" :key="ability.$id">
          {{ ability.technology }}
          <font-awesome-icon
            v-if="ability.icon"
            class="icon"
            :icon="['fab', ability.icon]"
          ></font-awesome-icon>
        </li>
      </ul>
      <!--TODO-->
    </div>
  </div>
  <div class="error-loading" v-else-if="errorLoading">
    {{ $t("errorDataAppwrite") }}
    <button @click="getAppwriteData">Recargar</button>
  </div>
</template>
<style lang="scss" scoped>
.abilities-div {
  display: grid;
  padding: 5px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 5px;
  h1 {
    text-align: center;
    grid-column: 1 / -1;
  }
  div {
    width: auto;
    padding: 10px;
    background: var(--abilities-card);
    min-height: 300px;
    min-width: 300px;
    border-radius: 10px;
    border: 2px solid var(--app-green);
  }
  h3 {
    color: var(--main-color);
    padding: inherit;
  }
  background-color: var(--abilities-card);
  margin: 20px;
  ul {
    padding: inherit;
    list-style: none;
    li::before {
      content: "\1f538";
      color: var(--main-color);
    }
    li {
      font-size: larger;
      font-weight: 300;
    }
  }
  border-radius: 10px;
  animation: fadeIn;
  animation-duration: 0.5s;
  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    h1 {
      align-self: center;
      font-size: x-large;
    }
    h3 {
      text-align: center;
    }
  }
}
.loading-div {
  width: 100%;
  background-color: var(--abilities-card);
  padding: 30px 0;
  position: fixed;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    padding: 0 0 20px 0;
    text-align: center;
  }
  .breeding-rhombus-spinner {
    padding: 10px;
    :deep(.rhombus) {
      background-color: var(--main-color) !important;
    }
  }
}
.error-loading {
  width: 100%;
  background-color: var(--abilities-card);
  padding: 30px 0;
  position: fixed;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    background: none;
    color: inherit;
    border: 1px solid var(--app-green);
    padding: 1px 5px;
    border-radius: 50px;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    &:hover {
      background: var(--app-green);
    }
  }
}
</style>
<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useDataStore } from "@/pinia/DataStore";
import { onBeforeMount, ref } from "vue";
import { AppwriteException, Models } from "appwrite";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { BreedingRhombusSpinner } from "epic-spinners";

const i18n = useI18n();
const data = useDataStore();
const refData = ref<Models.DocumentList<Models.Document>>({
  total: 0,
  documents: [],
});
const loaded = ref(false);
const errorLoading = ref(false);
onBeforeMount(async () => {
  await getAppwriteData();
});
async function getAppwriteData() {
  try {
    errorLoading.value = false;
    loaded.value = false;
    refData.value = await data.appwriteInstance.listAbilitiesGroup();
  } catch (e: AppwriteException | unknown) {
    if (e instanceof AppwriteException)
      console.error("Error retrieving Appwrite data");
    else console.error("Unknown error");
    errorLoading.value = true;
  }
  loaded.value = true;
}
</script>
