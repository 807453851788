import DefaultLocales from "./Default";

export default class enUS implements DefaultLocales {
  readonly changeLocale = "Change language";
  readonly workInProgress = "This is a work in progress";
  readonly abilities = "Abilities and knowledge";
  readonly home = "Home";
  readonly about = "About";
  readonly aboutHeader = "About the project";
  readonly aboutMe = "About me";
  readonly aboutProject_1 = `This project has been built with Vue 3, written in TypeScript,
   and using Appwrite, which is an Open Source alternative to Firebase, credits to me, Adrian Rey Brea as the developer of this project.`;
  readonly aboutProject_2 = "Other libraries that has been used are:";
  readonly errorDataAppwrite = "Error getting Appwrite data, please try again";
  readonly loadingAppwrite = "Getting data from Appwrite, please stand by";
  readonly aboutInfo = {
    job: "Software Developer",
    links: "Links",
    paragraphs: [
      "Hello there, my name is Adrian Rey I'm a software developer located in San Andrés del Rabanedo, León (Spain).",
      "For the last 2 years, I've been working in an e-commerce called Craftelier developing PHP (Using Laravel / Symfony) and TypeScript (Fullstack with Vue 3 and NestJS), also I've been supervising servers and deploying applications in them via CI/CD and Docker, and I've built servers for services like GitLab.",
      "I attended a Vocational Education from 2019 to 2021 where I learned Java 11, Python and SQL using MySQL, SQLite, PostgreSQL and Oracle as DB engines and gained the title that accredits me as a software developer.",
      "My final project was an application called MultiSQL Workbench (as a parody of MySQL Workbench) written in Java Swing allowing the use of different DB engines such as SQLite, PostgreSQL and Oracle.",
      "Later that year i started working part-time at a company called Proconsi where I was introduced to Spring. At the same time I attended further studies and obtained another accreditation as web developer from 2021 to 2022 (the first year was common to the other Vocational Education), here I learned PHP, Laravel, SCSS, Bootstrap and React, also improved my knowledge of HTML and CSS as they were a basic approach in the first year.",
      "For the final project, the teachers asked me and another three students to do a collaborative development to make an app for the educational institution, we built a web application in React that connects to a backend written in Spring and using Firebase as Database.",
    ],
  };
}
